import React, { useContext, useEffect, useState } from "react";
import DataContext from "../data/context";
import { useHistory, useParams, useLocation, Redirect } from "react-router-dom";
import { formatPrice, getDealerFromUrl } from "../utils";
import QRCode from "qrcode.react";
import EmailConfirm from "./EmailConfirm";
import { useMutation } from "graphql-hooks";
import { LoadingIndicator } from "./indicators";
import { useTranslation } from "react-i18next";
import { useValuation } from "../hooks/useValuation";
import { fetchUserDataByCode } from "../data/session";
import analyticstracker from "analyticstracker";
import { TAGMANAGER_PAGE_ARGS } from "../tagManager";

export const confirmEmailMutation = `mutation ConfirmEmail($data: ConfirmEmailAddressType!) { 
        confirmEmail(data: $data) {
            ok,            
        }
    }`;

export const sendInspectionEmailMutation = `mutation SendInspectionEmail($data: SendInspectionMailType!) { 
        sendInspectionEmail(data: $data) {
            ok,            
        }
    }`;

const Valuation = () => {
  const {
    inspection,
    inspectionStatus,
    fetchInspection,
    inspectionError,
    dealerKey,
    dealerConfig,
    setAuthenticated,
    setField,
  } = useContext(DataContext);
  const {
    valuation,
    loading: valuationLoading,
    hasValuation,
  } = useValuation(inspection);
  const { key } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [confirmMutation] = useMutation(confirmEmailMutation);
  const [sendInspectionMailMutation, { loading: resendPending }] = useMutation(
    sendInspectionEmailMutation
  );
  const [resendSuccess, setResendSuccess] = useState(false);
  const { t, i18n } = useTranslation();

  let history = useHistory();
  const showGuideValue = dealerConfig.valuationAverage;

  const searchParams = new URLSearchParams(location.search);
  let confirmKey = undefined;
  let userKey = undefined;

  if (searchParams.has("c")) {
    confirmKey = searchParams.get("c");
  }

  if (searchParams.has("u")) {
    userKey = searchParams.get("u");
  }

  useEffect(() => {
    let aTrack = analyticstracker();
    aTrack.trackImpression("page-impression");
  });

  const tagInfo = TAGMANAGER_PAGE_ARGS({
    section: "valuation",
    language: i18n.language,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (inspection.key === undefined) {
      fetchInspection(key, getDealerFromUrl(window.location.pathname));
    }
  }, [key]);

  useEffect(() => {
    if (confirmKey && userKey) {
      handleConfirmation(confirmKey, userKey);
    } else {
      setLoading(false);
    }
  }, []);

  const handleConfirmation = (confirmationKey, userKey) => {
    confirmMutation({
      variables: {
        data: {
          inspectionKey: key,
          confirmationKey: confirmationKey,
          userKey: userKey,
        },
      },
    }).then((result) => {
      if (result.data && result.data.confirmEmail) {
        if (result.data.confirmEmail.ok) {
          fetchUserDataByCode(dealerKey, userKey).then((user) => {
            if (user) {
              setField("userId", user.id);
              setAuthenticated(user);
              setLoading(false);
            }
          });

          history.replace({
            ...location,
            search: "",
          });
        }
      }
    });
  };

  const handleSendInspectionMail = () => {
    setResendSuccess(false);
    sendInspectionMailMutation({
      variables: {
        data: {
          inspectionKey: inspection.key,
        },
      },
    }).then((result) => {
      if (result.data && result.data.sendInspectionEmail) {
        if (result.data.sendInspectionEmail.ok) {
          setResendSuccess(true);
        }
      }
    });
  };

  if (
    (inspection.key === undefined && !inspectionError) ||
    loading ||
    (valuationLoading && inspection.userId)
  ) {
    return (
      <div className="flex flex-1 items-center justify-center w-screen h-screen">
        <LoadingIndicator />
      </div>
    );
  }

  if (inspectionError && inspection.key === undefined) {
    return (
      <Redirect
        to={`/${getDealerFromUrl(
          window.location.pathname
        )}/no-inspection-found`}
      />
    );
  }

  if (
    inspectionStatus &&
    (inspectionStatus === "COMPLETED" || inspectionStatus === "SYNCED")
  ) {
    return (
      <Redirect
        to={`/${getDealerFromUrl(window.location.pathname)}/inspection/${
          inspection.key
        }`}
      />
    );
  }

  if (inspection.userId === undefined || inspection.userId === null) {
    return <EmailConfirm />;
  }

  const QrCodeUrl = `${window.location.origin}/${getDealerFromUrl(
    window.location.pathname
  )}/inspection/${inspection.key}`;

  const renderPurchaseProject = () => {

    const customText = dealerConfig.valuationPurchaseProject?.html;
    
    if (!customText) {
      return
    }

    //If there's no purchaseproject don't show this
    if (inspection.purchase.onlyTakeOver) {
      return
    }
    const textToRender = customText
        .replaceAll("{{dealer_name}}", inspection.dealerName || t("our website"))
        .replaceAll(
          "{{dealer_website}}",
          inspection.dealerWebsite || dealerConfig.redirectUrl
        ); // Can be extended with anything

    return (
      <>
        <div className="flex-1 pl-8 pr-8 mt-10 mb-10 justify-center items-center">
          <p
            className="text-center items-center flex flex-col "
            dangerouslySetInnerHTML={{
              __html: textToRender,
            }}
          ></p>
        </div>
      </>
    );
  };
  const renderPriceIndication = () => {
    return (
      <div
        className={`flex flex-col md:w-2/5 ${
          !dealerConfig.valuation ? "hidden md:flex" : ""
        }`}
      >
        <div className="flex flex-col items-center bg-color_four pl-8 pr-8 pb-32">
          <i className="fal fa-car text-70 text-color_three mt-8 mb-8" />
          <div className="text-color_one text-24 font-bold mb-8">
            {t("Car price estimation")}
          </div>
          {dealerConfig.valuation && hasValuation && (
            <p
              className="leading-6 text-center"
              dangerouslySetInnerHTML={{
                __html: t(
                  "Based on your data, we were able to make a first takeover price estimation for your car. {{styling}} Attention! {{stylingEnd}} Depending on a number of unknown factors such as additional options, damage, ... this amount can vary and potentially even go outside of these limits.",
                  {
                    styling: '<span class="font-bold">',
                    stylingEnd: "</span />",
                    interpolation: { escapeValue: false },
                  }
                ),
              }}
            ></p>
          )}

          {dealerConfig.valuation && !hasValuation && (
            <p
              className="leading-6 text-center"
              dangerouslySetInnerHTML={{
                __html: t(
                  "Unfortunately we were not able to calculate a takeover price with this information. Please fill out the detailed inspection and we will get back to you with a correct price in 48hrs.",
                  {
                    styling: '<span class="font-bold">',
                    stylingEnd: "</span />",
                    interpolation: { escapeValue: false },
                  }
                ),
              }}
            ></p>
          )}

          {!dealerConfig.valuation && (
            <p
              className="leading-6 text-center"
              dangerouslySetInnerHTML={{
                __html: t(
                  "Through our simple mobile website you can use your smartphone to inspect your car yourself in {{styling}} only 5 steps! {{stylingEnd}} With the extra information, we’ll be able to estimate an accurate takeover price. It’ll only take up 15 minutes of your time and we’ll be here to guide you through the whole process.",
                  {
                    styling: '<span class="text-color_one font-bold">',
                    stylingEnd: "</span />",
                    interpolation: { escapeValue: false },
                  }
                ),
              }}
            ></p>
          )}
        </div>

        {dealerConfig.valuation && (
          <div
            className="flex flex-col bg-color_one rounded-xl ml-8 mr-8 bottom-0 flex z-20 relative"
            style={{ height: 205, marginTop: -90 }}
          >
            <React.Fragment>
              <div className="w-full">
                <div
                  className={`flex text-white  items-center ${
                    showGuideValue
                      ? "flex-col justify-center"
                      : "justify-between"
                  } justify-center h-12 mt-8 font-bold`}
                >
                  {hasValuation && showGuideValue && (
                    <React.Fragment>
                      <span className="text-12">{t("Average")}</span>
                      {valuation.guide && (
                        <span className="text-30">
                          {" "}
                          {formatPrice(valuation.guide)}
                        </span>
                      )}
                    </React.Fragment>
                  )}

                  {hasValuation && !showGuideValue && (
                    <div className="flex flex-1 flex-col items-center ">
                      {valuation.min && (
                        <React.Fragment>
                          <div>{t("From")}</div>
                          <div className="text-18">
                            {formatPrice(valuation.min)}
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  )}

                  {hasValuation && !showGuideValue && (
                    <div className="flex flex-1 flex-col items-center">
                      {valuation.max && (
                        <React.Fragment>
                          <div>{t("To")}</div>
                          <div className="text-18">
                            {formatPrice(valuation.max)}
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-end justify-center">
                <div
                  className="flex absolute justify-between bottom-0 left-0 right-0 pl-5 pr-5 text-white font-bold"
                  style={{ height: 70 }}
                >
                  {hasValuation && showGuideValue && (
                    <div className="flex flex-col items-center ">
                      {valuation.min && (
                        <div className="text-18">
                          {formatPrice(valuation.min)}
                        </div>
                      )}
                    </div>
                  )}

                  {hasValuation && showGuideValue && (
                    <div className="flex flex-col items-center">
                      {valuation.max && (
                        <div className="text-18">
                          {formatPrice(valuation.max)}
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <img
                  src={require("../assets/price-indicator.svg")}
                  style={{ width: 145, marginTop: 8 }}
                  alt="price-indicator"
                />
              </div>
            </React.Fragment>
          </div>
        )}
      </div>
    );
  };

  const renderExtendedInspectionInfo = () => {
    return (
      <>
        <div className="pl-8 pr-8 mt-10 mb-10 md:hidden">
          {dealerConfig.valuation && (
            <div className="text-color_one text-24 font-bold text-center">
              {t("Want a more accurate price indication?")}
            </div>
          )}

          <div className="text-color_one text-24 font-bold text-center mt-1 mb-8">
            {t("Inspect your car yourself!")}
          </div>

          <div className="text-color_three text-center mb-8">
            <p
              className="leading-6"
              dangerouslySetInnerHTML={{
                __html: t(
                  "Through our simple mobile website you can use your smartphone to inspect your car yourself in {{styling}} only 5 steps! {{stylingEnd}} It’ll only take up 15 minutes of your time and we will guide you through the whole process.",
                  {
                    styling: '<span class="text-color_one font-bold">',
                    stylingEnd: "</span />",
                    interpolation: { escapeValue: false },
                  }
                ),
              }}
            ></p>

            <p className="mt-8 leading-6">
              {t(
                "With the extra information, we’ll be able to estimate an accurate takeover price."
              )}
            </p>
          </div>

          <div
            className="flex rounded-full uppercase bg-color_one text-white items-center justify-center text-center font-bold text-14 mb-4 cursor-pointer"
            style={{ minHeight: 45 }}
            onClick={() =>
              history.push(
                `/${getDealerFromUrl(window.location.pathname)}/inspection/${
                  inspection.key
                }`
              )
            }
          >
            {t("Carry out the inspection yourself")}
          </div>
        </div>

        <div
          className="pl-8 md:bg-white md:rounded-lg md:pl-12 hidden md:flex md:flex-col md:w-1/2 md:pr-0"
          style={{ maxWidth: 480 }}
        >
          {/*{dealerConfig.valuation &&*/}
          <div className="text-color_one text-24 font-bold mb-8">
            {dealerConfig.valuation ? t("A more accurate price? ") : ""}{" "}
            {t("Inspect your car yourself with your smartphone!")}
          </div>

          {/*<div className="text-color_one text-24 font-bold mb-8">{t('A more accurate price? Inspect your car yourself with your smartphone!')}</div>*/}

          <div className="text-color_three mb-5">
            {dealerConfig.valuation && (
              <p
                className="leading-6 mb-8"
                dangerouslySetInnerHTML={{
                  __html: t(
                    "Through our simple mobile website you can use your smartphone to inspect your car yourself in {{styling}} only 5 steps! {{stylingEnd}} With the extra information, we’ll be able to estimate an accurate takeover price. It’ll only take up 15 minutes of your time and we’ll be here to guide you through the whole process.",
                    {
                      styling: '<span class="text-color_one font-bold">',
                      stylingEnd: "</span />",
                      interpolation: { escapeValue: false },
                    }
                  ),
                }}
              ></p>
            )}

            <div className="flex items-center justify-center">
              <p className="leading-6">
                {t(
                  "Grab your smartphone and scan the QR-code to start the detailed inspection. "
                )}
              </p>

              <div
                className="flex justify-center ml-8"
                style={{ maxWidth: 140, maxHeight: 140 }}
              >
                <QRCode value={QrCodeUrl} />
              </div>
            </div>

            <div className="mt-8 mb-10">
              <div className="mb-1">{t("No QR-code scanner?")}</div>

              <div className="flex">
                {(resendSuccess || resendPending) && (
                  <div className="mr-2">
                    {resendSuccess ? (
                      <i className="fa fa-check text-green-500" />
                    ) : resendPending ? (
                      <i className="fal fa-spinner" />
                    ) : (
                      ""
                    )}
                  </div>
                )}
                <div
                  className="text-color_one underline cursor-pointer "
                  onClick={handleSendInspectionMail}
                >
                  {" "}
                  {t("Send me the link in an e-mail.")}
                </div>
              </div>
            </div>

            <hr className="solid border-color-five mb-10" />

            <div className="text-black text-24 font-bold">
              {t("No smartphone?")}
            </div>

            <p className="leading-6 mt-8">
              {t(
                "No problem! Do the inspection on your desktop. Just make sure you have a camera on hand to upload pictures of your car."
              )}
            </p>
          </div>

          <div className="flex">
            <div
              className="btn-primary flex rounded-full uppercase bg-white border-2 border-black text-center items-center justify-center font-bold text-12 cursor-pointer tracking-widest px-6"
              style={{ minHeight: 40 }}
              onClick={() =>
                history.push(
                  `/${getDealerFromUrl(window.location.pathname)}/inspection/${
                    inspection.key
                  }`
                )
              }
            >
              {t("Start inspection")}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="flex flex-1 md:justify-center md:mb-12 md:mt-12">
      <div
        className="md:flex md:justify-center"
        style={{ maxWidth: 1024 }}
        data-tracking-event="page-impression"
        data-tracking-info={tagInfo}
      >
        {renderPriceIndication()}
        {dealerConfig.showInspection
          ? renderExtendedInspectionInfo()
          : renderPurchaseProject()}
      </div>
    </div>
  );
};
export default Valuation;
