import React, {useContext, useEffect, useState} from 'react';
import DataContext from "../../data/context";
import VehicleWizard from "./VehicleWizard";
import {getDealerFromUrl} from "../../utils";
import {useMutation, ClientContext} from "graphql-hooks";
import {useTranslation} from "react-i18next";
import {LoadingIndicator} from "../indicators";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

export const initializeInspectionMutation =
    `mutation InspectionInitialize($data: InitializeInspectionType!) { 
        inspectionInitialize(data: $data) { 
            ok,
            key
        }
    }`;


const VehicleData = ({onComplete}) => {
        const {inspection, setKey, vehicleWizardConfiguration, vehicleWizardStep, setPrevVehicleDataStep, inspectionReset} = useContext(DataContext);
        const [initializeInspection] = useMutation(initializeInspectionMutation);
        const {i18n} = useTranslation();
        const {executeRecaptcha} = useGoogleReCaptcha();
        const [error, setError] = useState(undefined);
        const client = useContext(ClientContext);
        const [executing, setExecuting] = useState(false);

        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);


        useEffect(() => {
            if (vehicleWizardStep === vehicleWizardConfiguration.length && inspection.key === undefined) {
                handleInspectionInit();
            }
            else if (vehicleWizardStep === vehicleWizardConfiguration.length && inspection.key !== undefined) {
                inspectionReset()
            }
        }, [vehicleWizardStep]);


        const getComponent = (step) => {
            if ((step > -1) && (step <= (vehicleWizardConfiguration.length - 1))) {
                return (
                    <div className="flex items-center justify-center">
                        <VehicleWizard/>
                    </div>
                );
            } else if (step === (vehicleWizardConfiguration.length) && executing) {
                return (
                    <div className="flex items-center justify-center bg-white" style={{height: 300}}>
                        <LoadingIndicator/>
                    </div>
                )
            } else {
                return null;
            }
        };


        const handleInspectionInit = async () => {
            if (!executeRecaptcha || executing) {
                return;
            }

            const token = await executeRecaptcha("valuation");

            if (!error) {
                setError(undefined)
            }

            client.setHeader('Captcha', token);


            setExecuting(true);

            initializeInspection({
                variables: {
                    data: {
                        guid: (inspection.guid && inspection.guid != "") ? inspection.guid : undefined,
                        customer: getDealerFromUrl(window.location.pathname),
                        language: i18n.language,
                        make: inspection.make,
                        model: inspection.model,
                        licensePlate: inspection.licensePlate || "",
                        vin: inspection.vin || "",
                        fuel: inspection.fuel,
                        bodyType: inspection.body,
                        transmission: inspection.transmission,
                        mileage: inspection.mileage,
                        firstRegistrationYear: inspection.firstRegistrationYear,
                        firstRegistrationMonth: inspection.firstRegistrationMonth,
                        kw: inspection.kw,
                        engineRange: inspection.engineRange,
                        email: inspection.email,
                        user: inspection.userId,
                        contact: (inspection.firstName !="" && inspection.lastName) ? {
                            title: inspection.title,
                            firstName: inspection.firstName,
                            lastName: inspection.lastName,
                            street: inspection.street,
                            number: inspection.number,
                            bus: inspection.bus,
                            postalCode: inspection.postalCode,
                            city: inspection.city,
                            email: inspection.email,
                            telephone: inspection.telephone,
                            newsletter: inspection.newsletter,
                        } : undefined,
                        purchase: inspection.purchase,
                        dealer: inspection.dealer,
                        dealerName: inspection.dealerName,
                        dealerWebsite: inspection.dealerWebsite,
                        consent : {
                            dealerGroup: inspection.consent.dealerGroup
                        }
                    },
                    captcha: token,
                }
            }).then((result) => {
                setExecuting(false);
                if (result.data && result.data.inspectionInitialize && result.data.inspectionInitialize.key) {
                    setKey(result.data.inspectionInitialize.key)
                    onComplete(result.data.inspectionInitialize.key)
                } else if(result.error && result.error.graphQLErrors && result.error.graphQLErrors.length > 0) {
                    const error = result.error.graphQLErrors[0].message
                    setError(error)
                    // setPrevVehicleDataStep()
                }else {
                    setError('Something went wrong')
                }
            }).catch(() => {
                setExecuting(false);
            })

        };

        return (
            <React.Fragment>

                {error &&
                <div className="flex items-center justify-center mb-2 mt-10">
                    <div className="text-color_error text-14">{error}</div>
                </div>}

                {getComponent(vehicleWizardStep)}

            </React.Fragment>
        );
    }
;

export default VehicleData;
