import { ValuationSteps } from "./constants";
import { map, filter, find } from "lodash";

export const initialInspectionData = {
  key: undefined,
  customer: undefined,
  make: undefined,
  model: undefined,
  mileage: undefined,
  fuel: undefined,
  engineRange: undefined,
  kw: undefined,
  body: undefined,
  transmission: undefined,
  licensePlate: undefined,
  vin: undefined,
  firstRegistrationYear: undefined,
  firstRegistrationMonth: undefined,
  interiorColor: undefined,
  interiorColorText: undefined,
  interiorUpholstery: undefined,
  interiorUpholsteryText: undefined,
  interiorQuality: undefined,
  exteriorColor: undefined,
  exteriorColorText: undefined,
  exteriorQuality: undefined,
  features: [],
  pictures: [],
  noDamages: undefined,
  damages: [],
  checks: [],
  email: "",
  userId: undefined,
  title: "",
  firstName: "",
  lastName: "",
  telephone: "",
  street: "",
  number: "",
  bus: "",
  postalCode: "",
  city: "",
  comment: "",
  dealer: "",
  newsletter: false,
  purchase: undefined,
  consent: {
    dealerGroup: undefined
  },
};

export const initialTakeOver = {
  state: undefined,
  proposed: false,
  proposedAmount: undefined,
  proposedDate: undefined,
  accepted: false,
  acceptedAmount: undefined,
  acceptedDate: undefined,
  documentUrl: undefined,
  expertId: undefined,
  createdDate: undefined,
  documents: [],
  conventionUploaded: undefined,
};

export const initialState = {
  loading: true,
  inspection: initialInspectionData,
  inspectionStatus: undefined,
  dealer: undefined,
  dealerConfig: undefined,
  takeOver: initialTakeOver,
};

export const actionTypes = {
  VALUATION_SET_STEP: "VALUATION_SET_STEP",
  NEXT_STEP: "NEXT_STEP",
  PREV_STEP: "PREV_STEP",

  INSPECTION_SET: "INSPECTION_SET",
  INSPECTION_SET_KEY: "INSPECTION_SET_KEY",
  INSPECTION_SET_FIELD: "INSPECTION_SET_FIELD",
  INSPECTION_ADD_PICTURE: "INSPECTION_ADD_PICTURE",
  INSPECTION_DELETE_PICTURE: "INSPECTION_DELETE_PICTURE",

  INSPECTION_ADD_DAMAGE: "INSPECTION_ADD_DAMAGE",
  INSPECTION_DAMAGE_ADD_PICTURES: "INSPECTION_DAMAGE_ADD_PICTURES",
  INSPECTION_DAMAGE_REMOVE_PICTURE: "INSPECTION_DAMAGE_REMOVE_PICTURE",
  INSPECTION_SET_CHECK_VALUE: "INSPECTION_SET_CHECK_VALUE",

  SET_DEALER: "SET_DEALER",

  VEHICLE_MAKE: "VEHICLE_MAKE",
  VEHICLE_MODEL: "VEHICLE_MODEL",
  VEHICLE_FUEL: "VEHICLE_FUEL",
  VEHICLE_LICENSE_PLATE: "VEHICLE_LICENSE_PLATE",
  VEHICLE_VIN: "VEHICLE_VIN",
  VEHICLE_FIRST_REGISTRATION: "VEHICLE_FIRST_REGISTRATION",
  CONTACT_INFO: "CONTACT_INFO",

  INSPECTION_RESET: "INSPECTION_RESET",

  TAKE_OVER_SET: "TAKE_OVER_SET",
  TAKE_OVER_DOCUMENT_INITIALIZE: "TAKE_OVER_DOCUMENT_ADD",
  TAKE_OVER_DOCUMENT_UPDATE: "TAKE_OVER_DOCUMENT_UPDATE",
  TAKE_OVER_DOCUMENT_DELETE: "TAKE_OVER_DOCUMENT_DELETE",
  TAKE_OVER_SET_CONFIRMED: "TAKE_OVER_SET_CONFIRMED",
};

export const dataReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.VEHICLE_MAKE:
    case actionTypes.VEHICLE_MODEL:
    case actionTypes.VEHICLE_FUEL:
    case actionTypes.VEHICLE_FIRST_REGISTRATION:
    case actionTypes.INSPECTION_RESET:
    case actionTypes.CONTACT_INFO:
    case actionTypes.INSPECTION_SET_FIELD:
    case actionTypes.INSPECTION_ADD_PICTURE:
    case actionTypes.INSPECTION_DELETE_PICTURE:
    case actionTypes.INSPECTION_ADD_DAMAGE:
    case actionTypes.INSPECTION_DAMAGE_ADD_PICTURES:
    case actionTypes.INSPECTION_DAMAGE_REMOVE_PICTURE:
    case actionTypes.INSPECTION_SET_CHECK_VALUE:
      return {
        ...state,
        inspection: inspectionReducer(state.inspection, action),
      };
    case actionTypes.INSPECTION_SET:
    case actionTypes.INSPECTION_SET_KEY:
      return {
        ...state,
        valuationStep: ValuationSteps.result,
        inspection: inspectionReducer(state.inspection, action),
        inspectionStatus: action.payload.fullInspectionStatus,
      };
    case actionTypes.VALUATION_SET_STEP:
      return {
        ...state,
        valuationStep: action.payload.step,
      };
    case actionTypes.SET_DEALER:
      return {
        ...state,
        dealer: {
          id: action.payload.dealer.id,
          name: action.payload.dealer.name,
          key: action.payload.dealer.key,
          address: action.payload.dealer.address,
          postal: action.payload.dealer.postal,
          city: action.payload.dealer.city,
          telephone: action.payload.dealer.telephone,
          email: action.payload.dealer.email,
          facebook: action.payload.dealer.facebook,
          instagram: action.payload.instagram,
          twitter: action.payload.dealer.twitter,
          website: action.payload.dealer.website,
        },
        dealerConfig: {
          makes: action.payload.dealer.makes,
          home: action.payload.dealer.homePage,
          header: action.payload.dealer.header,
          footer: action.payload.dealer.footer,
          inspectionStart: action.payload.dealer.inspectionStart,
          transport: action.payload.dealer.transport,
          account: action.payload.dealer.account,
          valuation: action.payload.dealer.valuation,
          valuationAverage: action.payload.dealer.valuationAverage,
          showInspection: action.payload.dealer.inspection,
          logo: action.payload.dealer.logo,
          defaultLanguage: action.payload.dealer.defaultLanguage,
          htmlHeadScript: action.payload.dealer.htmlHeadScript,
          htmlHeadNoScript: action.payload.dealer.htmlHeadNoScript,
          htmlHeadScripts: action.payload.dealer.htmlHeadScripts,
          fontUrl: action.payload.dealer.fontUrl,
          pageTitle: action.payload.dealer.pageTitle,
          favIcon: action.payload.dealer.favIcon,
          headerImage: action.payload.dealer.headerImage,
          copyright: action.payload.dealer.copyright,
          copyrightLogo: action.payload.dealer.copyrightLogo,
          privacyUrl: action.payload.dealer.privacyUrl,
          termsAndConditionsUrl: action.payload.dealer.termsAndConditionsUrl,
          cookieBanner: action.payload.dealer.cookieBanner,
          redirectUrl: action.payload.dealer.redirectUrl,
          reloadOnLanguageChange: action.payload.dealer.reloadOnLanguageChange,

          dealerLocator: action.payload.dealer.dealerLocator,
          advancedIdentificationForm:
            action.payload.dealer.advancedIdentificationForm,
          communicationPreferencesDealergroup: action.payload.dealer.communicationPreferencesDealergroup,
          advancedIdentificationFormAddressRequired:
            action.payload.dealer.advancedIdentificationFormAddressRequired,
          valuationPurchaseProject: action.payload.dealer.valuationPurchaseProject,
          purchaseMakes: action.payload.dealer.purchaseMakes,
          purchaseProject: action.payload.dealer.purchaseProject,
          socialAuthentication: action.payload.dealer.socialAuthentication,
          formsPrivacyTickBox: action.payload.dealer.formsPrivacyTickBox,
          googleTagManagerContainerId:
            action.payload.dealer.googleTagManagerContainerId,
        },
      };
    case actionTypes.TAKE_OVER_SET:
    case actionTypes.TAKE_OVER_DOCUMENT_INITIALIZE:
    case actionTypes.TAKE_OVER_DOCUMENT_UPDATE:
    case actionTypes.TAKE_OVER_DOCUMENT_DELETE:
      return {
        ...state,
        takeOver: takeOverReducer(state.takeOver, action),
      };
    default:
      throw new Error();
  }
};

export const inspectionReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.INSPECTION_SET:
      return {
        ...initialInspectionData,
        guid:
          action.payload.guid && action.payload.guid !== ""
            ? action.payload.guid
            : undefined,
        key: action.payload.key,
        customer: action.payload.customer,
        userId: action.payload.userId,
        make: action.payload.make,
        model: action.payload.model,
        fuel: action.payload.fuel,
        body: action.payload.bodyType,
        transmission: action.payload.transmission,
        licensePlate: action.payload.licensePlate,
        vin: action.payload.vin,
        mileage: action.payload.mileage,
        engineRange: action.payload.engineRange,
        kw: action.payload.kw,
        dealerName: action.payload.dealerName,
        dealerWebsite: action.payload.dealerWebsite,
        interiorColor: action.payload.interiorColor || undefined,
        interiorColorText: action.payload.interiorColorText || undefined,
        interiorQuality: action.payload.interiorQuality || undefined,
        interiorUpholstery: action.payload.interiorUpholstery || undefined,
        interiorUpholsteryText:
          action.payload.interiorUpholsteryText || undefined,
        exteriorColor: action.payload.exteriorColor || undefined,
        exteriorColorText: action.payload.exteriorColorText || undefined,
        exteriorQuality: action.payload.exteriorQuality || undefined,
        tyreQualityFront: action.payload.tyreQualityFront || undefined,
        tyreQualityBack: action.payload.tyreQualityBack || undefined,

        features: action.payload.features || [],
        pictures:
          map(action.payload.pictures, (p) => ({
            key: p.key,
            url: p.url,
            type: p.type,
          })) || [],

        noDamages:
          action.payload.noDamages === undefined ||
          action.payload.noDamages === null
            ? undefined
            : action.payload.noDamages,
        damages:
          map(action.payload.damages, (damage) => ({
            id: damage.id,
            location: damage.location,
            pictures: damage.pictures,
          })) || [],

        checks:
          map(action.payload.checks, (check) => ({
            key: check.key,
            value: check.value,
          })) || [],

        firstRegistrationYear: action.payload.firstRegistrationYear,
        firstRegistrationMonth: action.payload.firstRegistrationMonth,

        firstName:
          action.payload.contact && action.payload.contact.firstName
            ? action.payload.contact.firstName
            : "",
        lastName:
          action.payload.contact && action.payload.contact.lastName
            ? action.payload.contact.lastName
            : "",
        email:
          action.payload.contact && action.payload.contact.email
            ? action.payload.contact.email
            : action.payload.email,
        telephone:
          action.payload.contact && action.payload.contact.telephone
            ? action.payload.contact.telephone
            : "",
        street:
          action.payload.contact && action.payload.contact.street
            ? action.payload.contact.street
            : "",
        number:
          action.payload.contact && action.payload.contact.number
            ? action.payload.contact.number
            : "",
        bus:
          action.payload.contact && action.payload.contact.bus
            ? action.payload.contact.bus
            : "",
        postalCode:
          action.payload.contact && action.payload.contact.postalCode
            ? action.payload.contact.postalCode
            : "",
        city:
          action.payload.contact && action.payload.contact.city
            ? action.payload.contact.city
            : "",
        //comment: action.payload.comment,

        valuation: action.payload.valuation
          ? { ...action.payload.valuation }
          : undefined,
        purchase: action.payload.purchase
          ? { ...action.payload.purchase }
          : undefined,
      };

    case actionTypes.INSPECTION_SET_KEY:
      return {
        ...state,
        key: action.payload.key,
      };
    case actionTypes.INSPECTION_SET_FIELD:
      switch (action.payload.field) {
        case "make":
          return {
            ...state,
            make: action.payload.value,
            model: undefined,
            firstRegistrationMonth: undefined,
            firstRegistrationYear: undefined,
            body: undefined,
            fuel: undefined,
            kw: undefined,
            engineRange: undefined,
            transmission: undefined,
          };
        case "firstRegistrationYear":
          return {
            ...state,
            firstRegistrationYear: action.payload.value,
            firstRegistrationMonth: undefined,
            body: undefined,
            fuel: undefined,
            kw: undefined,
            engineRange: undefined,
            transmission: undefined,
          };
        case "firstRegistrationMonth":
          return {
            ...state,
            firstRegistrationMonth: action.payload.value,
            body: undefined,
            fuel: undefined,
            kw: undefined,
            engineRange: undefined,
            transmission: undefined,
          };
        case "model":
          return {
            ...state,
            model: action.payload.value,
            body: undefined,
            fuel: undefined,
            kw: undefined,
            engineRange: undefined,
            transmission: undefined,
          };
        default:
          return {
            ...state,
            [action.payload.field]: action.payload.value,
          };
      }
    case actionTypes.VEHICLE_MAKE:
      return {
        ...state,
        make: action.payload.make,
        model: undefined,
      };
    case actionTypes.VEHICLE_MODEL:
      return {
        ...state,
        model: action.payload.model,
      };
    case actionTypes.VEHICLE_FUEL:
      return {
        ...state,
        fuel: action.payload.fuel,
      };
    case actionTypes.VEHICLE_FIRST_REGISTRATION:
      return {
        ...state,
        firstRegistration: {
          year: action.payload.year,
          month: action.payload.month,
          day: action.payload.day,
        },
      };
    case actionTypes.INSPECTION_ADD_PICTURE:
      return {
        ...state,
        pictures: [
          ...state.pictures,
          {
            key: action.payload.key,
            url: action.payload.url,
            type: action.payload.type,
          },
        ],
      };
    case actionTypes.INSPECTION_DELETE_PICTURE:
      return {
        ...state,
        pictures: filter(
          state.pictures,
          (pic) => pic.key !== action.payload.key
        ),
      };

    case actionTypes.INSPECTION_ADD_DAMAGE:
      return {
        ...state,
        damages: [
          ...state.damages,
          {
            id: action.payload.clientDamageId,
            location: action.payload.location,
            pictures: [],
          },
        ],
      };
    case actionTypes.INSPECTION_DAMAGE_ADD_PICTURES:
      const damage = find(
        state.damages,
        (d) => d.id === action.payload.clientDamageId
      );
      return {
        ...state,
        damages: [
          ...filter(state.damages, (d) => d.id !== damage.id),
          {
            ...damage,
            pictures: [...damage.pictures, ...action.payload.pictureUrls],
          },
        ],
      };
    case actionTypes.INSPECTION_DAMAGE_REMOVE_PICTURE:
      // const current_damage = find(state.damages, (d) => d.id === action.payload.clientDamageId);
      return {
        ...state,
        damages: [
          ...filter(
            state.damages,
            (d) => d.id !== action.payload.clientDamageId
          ),
        ],
      };
    case actionTypes.INSPECTION_SET_CHECK_VALUE:
      if (find(state.checks, (check) => check.key === action.payload.key)) {
        return {
          ...state,
          checks: [
            ...filter(
              state.checks,
              (check) => check.key !== action.payload.key
            ),
            {
              key: action.payload.key,
              value: action.payload.value,
            },
          ],
        };
      } else {
        return {
          ...state,
          checks: [
            ...state.checks,
            {
              key: action.payload.key,
              value: action.payload.value,
            },
          ],
        };
      }
    case actionTypes.CONTACT_INFO:
      return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        telephone: action.payload.telephone,
        comment: action.payload.message,
        privacy: action.payload.privacy,
      };

    case actionTypes.INSPECTION_RESET:
      return {
        ...state,
        ...initialInspectionData,
      };
    default:
      throw new Error();
  }
};

export const takeOverReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.TAKE_OVER_SET:
      return {
        ...state,
        state: action.payload.state,
        proposed: action.payload.proposed,
        proposedAmount: action.payload.proposedAmount,
        proposedDate: action.payload.proposedDate,
        accepted: action.payload.accepted,
        acceptedAmount: action.payload.acceptedAmount,
        acceptedDate: action.payload.acceptedDate,
        documentUrl: action.payload.documentUrl,
        expertId: action.payload.expertId,
        createdDate: action.payload.createdDate,
        conventionUploaded: action.payload.conventionUploaded,
      };
    case actionTypes.TAKE_OVER_DOCUMENT_INITIALIZE:
      return {
        ...state,
        documents: [
          ...state.documents,
          {
            key: action.payload.key,
            url: undefined,
            originalFileName: action.payload.name,
            extension: action.payload.extension,
          },
        ],
      };
    case actionTypes.TAKE_OVER_DOCUMENT_UPDATE:
      let document = find(
        state.documents,
        (doc) => doc.key === action.payload.key
      );
      return {
        ...state,
        documents: [
          ...filter(state.documents, (doc) => doc.key !== action.payload.key),
          {
            ...document,
            url: action.payload.url,
          },
        ],
      };
    case actionTypes.TAKE_OVER_SET_CONFIRMED:
      return {
        ...state,
        conventionSend: action.payload.value,
      };
    case actionTypes.TAKE_OVER_DOCUMENT_DELETE:
      return {
        ...state,
      };
    default:
      throw new Error();
  }
};
