import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../data/context";
import { useTranslation } from "react-i18next";
import { filter, keys, map, orderBy } from "lodash";
import WizardButtons from "../WizardButtons";
import SelectBox from "../../input/SelectBox";
import analyticstracker from "analyticstracker";
import { useQuery, useManualQuery } from "graphql-hooks";
import {
  TAGMANAGER_TOOL_ARGS,
  TAGMANAGER_COMMERCE_ARGS,
} from "../../../tagManager";
import Select from "react-select";

const PURCHASE_BUDGET_OPTIONS_QUERY = `query PurchaseBudget($dealerId: ID!){
	purchaseBudget (dealerId: $dealerId) {
		key
		name
	}
}`;

const PURCHASE_TIMING_OPTIONS_QUERY = `query PurchaseTiming($dealerId: ID!){
	purchaseTiming (dealerId: $dealerId) {
		key
		name
	}
}`;

const PURCHASE_MODEL_FAMILIES_QUERY = `query PurchaseModelFamilies($externalId: ID!){
	purchaseModelFamilies (externalId:$externalId) {
		name
		id

	}
}`;

const Purchase = () => {
  const { inspection, setField, dealer, dealerConfig } =
    useContext(DataContext);
  const { data: budgetData, refetch: reFetchBudgetOption } = useQuery(
    PURCHASE_BUDGET_OPTIONS_QUERY,
    { variables: { dealerId: dealer.id } }
  );

  const { data: timingData, refetch: reFetchTimingOptions } = useQuery(
    PURCHASE_TIMING_OPTIONS_QUERY,
    {
      variables: { dealerId: dealer.id },
    }
  );

  const [fetchModelFamilies, { data: modelFamilyData, loading: modelFamilyLoading }] =
    useManualQuery(PURCHASE_MODEL_FAMILIES_QUERY);

  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const purchaseMakes = orderBy(
    dealerConfig.purchaseMakes,
    ["priority", "name"],
    ["asc", "asc"]
  );

  const purchase = inspection.purchase
    ? inspection.purchase
    : {
        make: "",
        type: "",
        onlyTakeOver: undefined,
        budget: null,
        timing: null,
      };

  useEffect(() => {
    if (errors && keys(errors).length > 0) {
      validate();
    }
  }, [inspection]);

  useEffect(() => {
    let aTrack = analyticstracker();
    aTrack.trackImpression("tool-start");
  }, []);

  const tagArgsStart = TAGMANAGER_TOOL_ARGS({
    event: "start",
    toolStep: "new-car",
    toolStepNumber: "10",
  });

  const tagArgsCommerce = TAGMANAGER_COMMERCE_ARGS({});
  // needed for sending submit event
  let option;
  if (purchase.onlyTakeOver) {
    option = "no";
  } else {
    const optionType = purchase.type === "used" ? "second-hand" : "new";
    option = `yes|${optionType}|${purchase.make}`;
  }

  const validate = () => {
    let errors = {};

    const trackError = (error) => {
      let aTrack = analyticstracker();
      let errorTrackingInfo = TAGMANAGER_TOOL_ARGS({
        event: "error",
        toolStep: "new-car",
        toolStepNumber: "10",
        errorType: "user-error",
        errorMessage: error,
      });
      let event = {
        event: "tool-error",
        info: JSON.parse(errorTrackingInfo),
        commerce: JSON.parse(tagArgsCommerce),
      };
      aTrack.trackEvent(event);
    };

    if (!purchase.onlyTakeOver) {
      if (purchase.type === "" && purchase.make === "") {
        errors["purchase"] = t(
          "Please fill in the car info or indicate you're not interested in a new car"
        );
        trackError(errors.purchase);
      } else if (purchase.type === "") {
        errors["purchase"] = t("Please select a type for your new car");
        trackError(errors.purchase);
      } else if (purchaseMakes.length && purchase.make === "") {
        errors["purchase"] = t("Please select a make");
        trackError(errors.purchase);
      }
    }

    setErrors(errors);

    return keys(errors).length <= 0;
  };

  const validator = () => {
    return validate();
  };

  const changeMake = (make) => {
    fetchModelFamilies({ variables: { externalId: make.externalId } });
    setField("purchase", {
      ...purchase,
      make: make.key,
      onlyTakeOver: false,
    });
  };

  const changeModelFamily = (family) => {
     setField("purchase", {
       ...purchase,
       modelFamily: {
        id: family.id,
        name: family.name,
       }
     });
  }
  const changeType = (type) => {
    if (purchaseMakes.length === 1) {
      changeMake(purchaseMakes[0].key);
      setField("purchase", {
        ...purchase,
        make: purchaseMakes[0].key,
        type: type,
        onlyTakeOver: false,
      });
    } else {
      setField("purchase", {
        ...purchase,
        type: type,
        onlyTakeOver: false,
      });
    }
  };

  const handleOnlyTakeOver = (value) => {
    setField("purchase", {
      make: "",
      type: "",
      onlyTakeOver: value,
      budget: null,
      timing: null,
    });
  };

  const renderPurchaseOptions = (budgetOptions, timingOptions) => {
    return (
      <React.Fragment>
        <div className="mb-6">
          <label className="block uppercase tracking-wide text-14 font-bold mb-1 text-color_one tracking-widest">
            {t("What budget do you have in mind?")}
          </label>

          <Select
            options={budgetOptions}
            onChange={(option) => {
              setField("purchase", { ...purchase, budget: option.key });
            }}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.key}
            placeholder={t("Click to select")}
            className="cursor-pointer"
            classNamePrefix="SelectField"
            isSearchable={false}
          />
        </div>
        <div>
          <label className="block uppercase tracking-wide text-14 font-bold mb-1 text-color_one tracking-widest">
            {t("When do you plan to make your purchase?")}
          </label>

          <Select
            options={timingOptions}
            onChange={(option) => {
              setField("purchase", { ...purchase, timing: option.key });
            }}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.key}
            placeholder={t("Click to select")}
            className="cursor-pointer"
            classNamePrefix="SelectField"
            isSearchable={false}
          />
        </div>
      </React.Fragment>
    );
  };
  return (
    <div
      data-tracking-event="tool-start"
      data-tracking-info={tagArgsStart}
      data-tracking-commerce={tagArgsCommerce}
    >
      <div className="text-color_one text-24 font-bold mb-4 mt-10 text-center">
        {t("Purchase")}
      </div>

      <div className="text-16 leading-6 mb-6 mt-6">
        {t(
          "Want to know what your new car wil cost? Use the takeover price of your old car for your next purchase"
        )}
      </div>

      <div className="flex flex-col justify-center mb-10">
        <div className="mb-6">
          <label className="block uppercase tracking-wide text-14 font-bold mb-1 text-color_one tracking-widest">
            {t("Vehicle type")}
          </label>

          <div className="flex flex-row py-3">
            <div className="flex flex-row mr-8 items-center">
              <div className="mr-4">
                <SelectBox
                  description=""
                  selected={purchase.type === "new"}
                  onChange={() => changeType("new")}
                  error={false}
                />
              </div>
              <div className="cursor-pointer" onClick={() => changeType("new")}>
                {t("New")}
              </div>
            </div>
            <div className="flex flex-row items-center">
              <div className="mr-4">
                <SelectBox
                  description=""
                  selected={purchase.type === "used"}
                  onChange={() => changeType("used")}
                  error={false}
                />
              </div>
              <div
                className="cursor-pointer"
                onClick={() => changeType("used")}
              >
                {t("Used")}
              </div>
            </div>
          </div>
        </div>

        {purchaseMakes?.length > 1 && (
          <div>
            <label className="block uppercase text-14 font-bold mb-1 text-color_one tracking-widest">
              {t("Make")}
            </label>

            <div className="flex flex-wrap py-3">
              {map(purchaseMakes, (make, i) => {
                const selected =
                  purchase && purchase.make
                    ? make.key === purchase.make
                    : false;
                return (
                  <div
                    key={`make_${make.key}`}
                    className={`w-1/3 mb-2 ${i % 3 !== 2 ? "pr-2" : ""}`}
                  >
                    <div
                      className={`flex items-center flex-col justify-center bg-color_five border text-14 rounded-lg cursor-pointer ${
                        selected
                          ? "border-color_one text-color_one"
                          : "border-color_five text-color_three"
                      } hover:border-color_one hover:text-color_one capitalize p-2`}
                      onClick={() => changeMake(make)}
                    >
                      <i
                        data-name={make.key.replace(" ", "-")}
                        className={`car-logo icon-${make.key.replace(
                          " ",
                          "-"
                        )} ${selected ? "text-color_one" : ""}`}
                        style={{ fontSize: 50 }}
                      />
                      <div className="mt-2 w-full text-center">{make.name}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {((modelFamilyData?.purchaseModelFamilies?.length > 1 &&
          !modelFamilyLoading) || purchase?.modelFamily?.id) &&(
            <div>
              <label className="block uppercase text-14 font-bold mb-1 text-color_one tracking-widest">
                {t("Model family")}
              </label>

              <div
                className="overflow-auto overflow-y-scroll my-4"
                style={{ height: "40vh" }}
              >
                {map(modelFamilyData?.purchaseModelFamilies, (family, i) => {
                  const isSelected = purchase && purchase?.modelFamily?.id
                    ? family.id === purchase?.modelFamily.id
                    : false;
                  return (
                    <div
                      key={family.id}
                      onClick={() => changeModelFamily(family)}
                      className={`flex flex-row p-3 my-2 mr-2 border cursor-pointer ${
                        isSelected
                          ? "border-color_one text-color_one"
                          : "border-color_five text-color_three"
                      }`}
                    >
                      <div className="flex-1">
                        <div className="text-color_one">{family.name}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        {timingData &&
          budgetData &&
          renderPurchaseOptions(
            budgetData.purchaseBudget,
            timingData.purchaseTiming
          )}
        <div
          style={{ height: 10 }}
          className="w-full border-b border-color_five text-center mb-5 mt-5"
        >
          <span
            style={{ color: "#B9BED1" }}
            className="text-10 pl-4 pr-4 bg-white"
          >
            {t("or")}
          </span>
        </div>
        <div className="flex flex-row justify-between text-color_three text-14 ">
          <div>{t("I'm not interested buying a new car")}</div>

          <div className="ml-4">
            <SelectBox
              description=""
              selected={
                purchase && purchase.onlyTakeOver
                  ? purchase.onlyTakeOver
                  : false
              }
              onChange={(value) => handleOnlyTakeOver(value)}
              error={false}
            />
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center mb-2">
        <div className="text-color_error text-12"> {errors["purchase"]}</div>
      </div>

      <WizardButtons
        validatorFunction={validator}
        trackingEvent="tool-submit"
        trackingInfo={TAGMANAGER_TOOL_ARGS({
          event: "submit",
          toolStep: "new-car",
          toolStepNumber: "10",
          toolStepOption: option,
        })}
      />
    </div>
  );
};

export default Purchase;
